export const FAQS = [
  { 
    question: '한번 무료로 사용해볼 수 있나요?',
    answer: '네 물론입니다. 현재 데피니션랩은 무료 플랜을 지원하고 있습니다. 다만 무료 플랜에서는 기능적인 제한이 있을 수 있다는 점 참고 부탁드립니다.'
  },
  { 
    question: '이용 중 구독을 자유롭게 취소할 수 있나요?',
    answer: '서비스가 마음에 들지 않거나 개인적인 이유 등으로 서비스 구독을 언제든지 자유롭게 취소하실 수 있습니다. 멤버쉽 구독 취소시 해당 달의 남아 있는 기간까지만 기능을 사용하실 수 있으며, 그 이후부터는 더 이상 금액이 청구되지 않습니다.'
  },
  { 
    question: '구독 중 멤버십 플랜을 변경할 수 있나요?',
    answer: '멤버십 플랜은 언제든지 변경하실 수 있습니다. 멤버쉽 플랜 변경 시 새로운 플랜으로 멤버쉽이 새롭게 시작 되며, 기존 플랜의 요금은 일할 계산하여 부분 환불된다는 점 알려드립니다.'
  },
  { 
    question: '랭킹 향상은 바로 적용이 되는 것인가요?',
    answer: '네이버 플레이스 랭킹의 경우 즉각적으로 반영되지 않으며, 어느 정도의 시간이 필요합니다. 일반적으로 한달에서 최대 3개월정도가 소요될 수 있으며 장기적인 관점에서 이용자님의 플레이스를 관리하는 부분이 매우 중요합니다.'
  },
  { 
    question: '네이버 이외의 플랫폼도 지원하나요?',
    answer: '현재 데피니션랩은 네이버 플레이스만 지원하고 있습니다. 추후 다른 플랫폼도 지원 계획 중에 있다는 점 안내드립니다.'
  },
  { 
    question: '위에는 없는 질문이 있는데 어디로 문의하면 될까요?',
    answer: '서비스와 관련하여 궁금하신 점이 있으시다면 언제든지 편하게 contact@definition-lab.com 으로 연락주세요. 빠르게 확인 후 답변드리도록 하겠습니다.'
  },
]
