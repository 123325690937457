import { images } from '@/utils/images';
import { Grid } from '@mantine/core';

export default function Feature() {
  return (
    <>
      <div className="max-w-[1280px] pt-[100px] sm:pt-[120px] pb-[40px] mx-auto px-6 md:px-10" id={'feature'}>
        <div className='text-center uppercase font-bold text-base text-blue-500'>기능</div>
        <div className='text-center text-2xl md:text-3xl font-bold text-gray-700 mt-2'>서비스 기능</div>
      </div>
      <Grid columns={24} className="max-w-[1280px] mx-auto" gutter={0}>
        <Grid.Col span={{ base: 24, sm: 12, md: 14, lg: 14 }}>
          <div className='m-2'>
            <div className='flex flex-col border-[0.5px] border-gray-200 shadow-sm rounded-3xl w-full h-[360px] sm:h-[480px] overflow-hidden'>
              <div className='px-4 pt-8 sm:px-6 sm:pt-12 flex flex-col gap-2 lg:gap-3 w-full'>
                <div className='text-xl pl-5 lg:text-2xl font-bold text-gray-800 tracking-tight'>
                  네이버 플레이스 리포트
                </div>
                <div className='text-base pl-5 lg:text-lg font-normal text-gray-600 tracking-tight'>
                  우리 매장의 상태를 리포트로 한눈에 확인해보고 주기적으로 관리해보세요
                </div>
                <div className='p-5 sm:p-8 mt-6 rounded-2xl bg-[#FEFAED] overflow-hidden'>
                  <img src={images.featureImage1} className='w-full rounded-xl shadow-xl transition !duration-700 ease-in-out hover:-translate-y-[45%]'/>
                </div>
              </div>
            </div>
          </div>
        </Grid.Col>
        <Grid.Col span={{ base: 24, sm: 12, md: 10, lg: 10 }}>
          <div className='m-2'>
            <div className='flex flex-col border-[0.5px] border-gray-200 shadow-sm rounded-3xl w-full h-[360px] sm:h-[480px] overflow-hidden'>
              <div className='px-4 pt-8 sm:px-6 sm:pt-12 flex flex-col gap-2 lg:gap-3 w-full'>
                <div className='text-xl pl-5 lg:text-2xl font-bold text-gray-800 tracking-tight'>
                  포스터 템플릿
                </div>
                <div className='text-base pl-5 lg:text-lg font-normal text-gray-600 tracking-tight'>
                  매장 운영과 홍보에 필요한 각종 포스터를 쉽고 간편하게 만들어보세요
                </div>
                <div className='p-5 sm:p-8 mt-6 rounded-2xl bg-[#EFFAFD] overflow-hidden'>
                  <img src={images.featureImage2} className='w-full rounded-2xl shadow-xl transition translate-x-[72px] translate-y-5 sm:translate-x-[108px] sm:translate-y-10 -rotate-[19deg] !duration-500 ease-in-out hover:-translate-y-[18px]'/>
                </div>
              </div>
            </div>
          </div>
        </Grid.Col>
        <Grid.Col span={{ base: 24, sm: 12, md: 10, lg: 10 }}>
          <div className='m-2'>
            <div className='flex flex-col border-[0.5px] border-gray-200 shadow-sm rounded-3xl w-full h-[360px] sm:h-[480px] overflow-hidden'>
              <div className='px-4 pt-8 sm:px-6 sm:pt-12 flex flex-col gap-2 lg:gap-3 w-full'>
                <div className='text-xl pl-5 lg:text-2xl font-bold text-gray-800 tracking-tight'>
                  순위 변동 알림
                </div>
                <div className='text-base pl-5 lg:text-lg font-normal text-gray-600 tracking-tight'>
                  우리 매장의 랭킹을 지속적으로 모니터링하고 랭킹 변동 사항을 카카오톡으로 간편하게 받아보세요
                </div>
                <div className='p-5 sm:p-8 mt-6 rounded-2xl bg-[#EEFBF6] overflow-hidden'>
                  <video src={images.featureVideo3} loop autoPlay autoFocus muted/>
                </div>
              </div>
            </div>
          </div>
        </Grid.Col>
        <Grid.Col span={{ base: 24, sm: 12, md: 14, lg: 14 }}>
          <div className='m-2'>
            <div className='flex flex-col border-[0.5px] border-gray-200 shadow-sm rounded-3xl w-full h-[360px] sm:h-[480px] overflow-hidden'>
              <div className='px-4 pt-8 sm:px-6 sm:pt-12 flex flex-col gap-2 lg:gap-3 w-full'>
                <div className='text-xl pl-5 lg:text-2xl font-bold text-gray-800 tracking-tight'>
                  키워드 분석
                </div>
                <div className='text-base pl-5 lg:text-lg font-normal text-gray-600 tracking-tight'>
                  고객들은 어떤 키워드를 많이 검색할까요? 고객들의 키워드 데이터를 한눈에 확인해보세요
                </div>
                <div className='p-5 sm:p-8 mt-6 rounded-2xl bg-[#f7f7f7] overflow-hidden'>
                  <img src={images.featureImage4} className='w-full rounded-2xl shadow-xl transition !duration-300 ease-in-out hover:scale-[105%]'/>
                </div>
              </div>
            </div>
          </div>
        </Grid.Col>
      </Grid>
    </>
  )
}