'use client'

import themeVariables from "@/config/theme/themeVariable"
import { images } from "@/utils/images";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Button, CloseButton, Divider, Modal } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks";
import ZoomImage from "./ZoomImage";

const INSTRUCTION_STEPS =  [
  { id: 1, instruction: '네이버에 이용자님의 매장을 검색 한 뒤, 공유 버튼을 클릭해주세요', imgUrl: images.instructionImage1 },
  { id: 2, instruction: '공유 링크를 복사해주세요', imgUrl: images.instructionImage2 },
  { id: 3, instruction: '복사된 링크를 검색창에 붙여넣어주세요', imgUrl: images.instructionImage3 },
]

export default function MyPlaceInstructionModal() {
  const [opened, { open, close }] = useDisclosure(false);

  const Step = ({ id, instruction, imgUrl }: { id: number, instruction: string, imgUrl: string }) => (
    <div className="flex flex-col items-start gap-5 mb-16">
      <div className="text-[15px] font-medium text-gray-700">
        {`${id}. ${instruction}`}
      </div>
      <ZoomImage
        imgUrl={imgUrl}
        className="w-full max-w-[420px] rounded-xl"
        alt={'instruction-image'}
      />
    </div>
  )

  return (
    <>
      <div className="flex gap-1.5 items-center">
        <QuestionMarkCircleIcon className="w-3.5 h-3.5 stroke-2 text-default-500"/>
        <div
          className="text-sm font-normal text-gray-600 hover:underline underline-offset-4 cursor-pointer"
          onClick={open}
        >
          매장의 네이버 URL는 어떻게 구하나요?
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={close}
        size={'lg'}
        centered
        padding={24}
        withCloseButton={false}
        transitionProps={{ keepMounted: true }}
        classNames={{ content: 'scrollbar-hide'}}
      >
        <div className="flex flex-col w-full m-auto gap-0">
          <div className="flex justify-between">
            <div className="text-base font-semibold tracking-tight" data-autofocus>
              네이버 플레이스 URL 구하기
            </div>
            <CloseButton onClick={close}/>
          </div>
          <Divider my={16}/>
          <div className="mt-4">
            {INSTRUCTION_STEPS.map(item => 
              <Step key={item.id} id={item.id} instruction={item.instruction} imgUrl={item.imgUrl}/>
            )}
          </div>
          <div className="flex justify-end mt-8">
            <Button
              classNames={themeVariables.button.dark}
              onClick={close}
            >
              확인
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}