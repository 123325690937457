'use client';

import MyPlaceInstructionModal from "@/components/MyPlaceInstructionModal";
import Toast from "@/components/Toast";
import { API_ENDPOINT } from "@/config/endpoint/endpoint";
import themeVariables from "@/config/theme/themeVariable";
import { mixpanelTrack } from "@/utils/mixpanel";
import { extractNaverPlaceUrl } from "@/utils/util";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button, Loader, TextInput } from "@mantine/core";
import { useRouter } from "next/navigation";
import { useState } from "react";


export default function Hero() {
  return (
    <div className="relative px-4 max-w-5xl mx-auto pt-20 sm:pt-24">
      <Header/>
      <div className="mt-6 sm:mt-10 flex justify-center space-x-6 text-sm">
        <SearchInput/>
      </div>
    </div>
  )
}


const Header = () => (
  <div className="relative isolate z-0">
    <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
      <div className="relative left-[calc(50% - 30rem))] aspect-[1155/678] w-[120rem] -translate-x-1/2 rotate-[200deg] bg-gradient-to-tr from-[#92a8ff] to-[#ff80b5] opacity-10 sm:left-[calc(50% - 30rem))] sm:w-[150rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
    </div>
    <h1 className="text-slate-900 font-extrabold text-3xl md:text-5xl !leading-normal text-center dark:text-white">
      <span className="text-blue-500 mx-1 md:mx-2">더 높은 랭킹</span>
      <span className="text-sky-500 mx-1 md:mx-2">더 많은 방문객</span>
      <span className="text-blue-600 ml-1 md:ml-2">더 큰 매출</span>
      을 위한<br/>가장 효과적인 솔루션
    </h1>
    <p className="mt-6 text-base md:text-lg text-gray-600 text-center max-w-3xl mx-auto dark:text-slate-400">
      우리 매장을{" "}
      <code className="font-semibold text-gray-600">
        상단에 노출
      </code>
      하고{" "}
      <code className="font-semibold text-gray-600">
      더 많은 고객들
      </code>
      의 발걸음을 매장으로 이끌어보세요.
    </p>
  </div>
)

const SearchInput = () => {
  const { push } = useRouter();
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false);

  const handleSearchButton = async () => {
    if (!input.startsWith('https://')) {
      Toast.info('매장 URL을 다시 한번 확인해주세요')
      return;
    }

    setLoading(true)
    mixpanelTrack('HomePage_SearchButton', 'Clicked', { input })
    const linkValidation = await API_ENDPOINT.place.checkPlaceUrl(input)
    if (!linkValidation.result) {
      Toast.info('매장 URL을 다시 한번 확인해주세요')
      setLoading(false)
      return;
    }

    push(`/my-place/preview?url=${input}`)
    setLoading(false)
  }


  return (
    <div className="flex flex-col w-full !max-w-[720px] z-10">
      <div className='!w-full flex flex-col sm:flex-row gap-2'>
        <TextInput
          type={'search'}
          value={input}
          classNames={{
            root: 'w-full',
            input: `${themeVariables.input.bordered.input} !h-[60px] !text-base !rounded-xl`,
          }}
          placeholder='매장의 네이버 URL을 입력해주세요'
          onChange={(e) => {
            const value = e.currentTarget.value
            if (value.length > 10) {
              const extractedUrl = extractNaverPlaceUrl(value)
              setInput(extractedUrl)
              return;
            }
            setInput(value)
          }}
          onKeyDownCapture={async (e) => {
            if (e.key == 'Enter') {
              e.preventDefault();
              handleSearchButton();
            }
          }}
        />
      <Button
        leftSection={
          loading 
          ? <Loader color={'white'} size={17} ml={3} mr={6}/>
          : <MagnifyingGlassIcon className="w-[17px] stroke-[2px] stroke-white"/>
        }
        classNames={{
          label: themeVariables.button.dark.label,
          root: `${themeVariables.button.dark.root} sm:my-auto sm:flex-shrink-0 sm:!h-[86%]`,
        }}
        onClick={() => {
          if (!loading) {
            handleSearchButton()
          }
        }}
      >
        {loading ? '분석 중' : '분석하기'}
      </Button>

        {/* {loading 
        ? <div className="flex gap-3">
            분석 중
            <Loader size={20}/>
          </div>
        : <ActionIcon m={'0 16px 0 auto'} variant={'transparent'} onClick={handleSearchButton} aria-label="search">
            <MagnifyingGlassIcon className="w-[20px] stroke-[2px] stroke-gray-600"/>
          </ActionIcon>} */}
      </div>
      <div className="mt-3">
        <MyPlaceInstructionModal/>
      </div>
    </div>
  )
}