'use client';

import { mixpanelTrackPageView } from "@/utils/mixpanel";
import Contact from "./_home/contact";
import Faq from "./_home/faq";
import Feature from "./_home/feature";
import Hero from "./_home/hero";
import Pricing from "./_home/pricing";
import Statistics from "./_home/statistics";
import Testimonial from "./_home/testimonial";
import { useEffect } from "react";

export default function HomePage() {

  useEffect(() => {
    mixpanelTrackPageView('HomePage');
  }, [])

  return (
    <div>
      <Hero/>
      <Statistics/>
      <Feature/>
      <Testimonial/>
      <Pricing/>
      <Faq/>
      <Contact/>
    </div>
  )
}