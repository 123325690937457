import { Grid } from "@mantine/core";

export default function Statistics() {
  return(
    <div className="max-w-[1280px] pt-[100px] mx-auto">
      <Grid gutter={0}>
        <Grid.Col span={{ base: 6, md: 6, lg: 3 }} className="flex justify-center">
          <div className="flex flex-col p-2 w-[160px] md:w-max" data-aos="fade" data-aos-delay="200" data-aos-duration="500">
            <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200">1번째 페이지 클릭율</h4>
            <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-blue-600">70%</p>
            <p className="mt-2 text-sm md:text-base text-gray-500">네이버 플레이스 기준</p>
          </div>
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 6, lg: 3 }} className="flex justify-center" data-aos="fade" data-aos-delay="400" data-aos-duration="500">
        <div className="flex flex-col p-2 w-[160px] md:w-max">
          <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200">이용 중인 사장님</h4>
          <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-blue-600">1,000+</p>
          <p className="mt-2 text-sm md:text-base text-gray-500">매장 기준</p>
        </div>
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 6, lg: 3 }} className="flex justify-center" data-aos="fade" data-aos-delay="600" data-aos-duration="500">
        <div className="flex flex-col p-2 w-[160px] md:w-max">
          <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200">분석된 플레이스</h4>
          <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-blue-600">200,000+</p>
          <p className="mt-2 text-sm md:text-base text-gray-500">이번 년도 기준</p>
        </div>
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 6, lg: 3 }} className="flex justify-center" data-aos="fade" data-aos-delay="800" data-aos-duration="500">
          <div className="flex flex-col p-2 w-[160px] md:w-max">
            <h4 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200">만족도</h4>
            <p className="mt-2 sm:mt-3 text-3xl sm:text-5xl font-bold text-blue-600">92%</p>
            <p className="mt-2 text-sm md:text-base text-gray-500">베타 사용자 기준</p>
          </div>
        </Grid.Col>
      </Grid>
    </div>
  )
}