import { FAQS } from '@/consts/faqs';
import { Container, Accordion } from '@mantine/core';

export default function Faq() {
  return (
    <Container className='pt-[100px] sm:pt-[120px] pb-[40px]' id={'faq'} w={'100%'}>
      <div className='text-center uppercase font-bold text-base text-blue-500'>자주 물어보는 질문</div>
      <div className='text-center text-2xl md:text-3xl font-bold text-gray-700 mb-10 mt-2'>FAQ</div>
      <div className='p-4' data-aos="fade-up" data-aos-delay="250" data-aos-duration="300">
        <Accordion variant={"separated"}>
          { FAQS.map((item, index) => (
            <Accordion.Item key={index} className='rounded-lg mb-4 border-1 border-gray-200' value={item.question}>
              <Accordion.Control classNames={{ label: 'text-sm md:text-base' }}>{item.question}</Accordion.Control>
              <Accordion.Panel className='text-sm md:text-base leading-6'>{item.answer}</Accordion.Panel>
            </Accordion.Item>
          ))}
          </Accordion>
      </div>
    </Container>
  );
}