import { ReadonlyURLSearchParams, usePathname, useRouter, useSearchParams } from "next/navigation";
import dayjs from "dayjs";
import isLeapYear from 'dayjs/plugin/isLeapYear';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ko';

dayjs.extend(isLeapYear); // 플러그인 등록
dayjs.extend(timezone);
dayjs.locale('ko'); // 언어 등록
dayjs.tz.setDefault('Asia/Seoul');

export const dateToString = (date: Date | number | string, format: string = 'YYYY-MM-DD') => dayjs(date).format(format)

export const sleep = (ms: number) => {
  return new Promise((r) => setTimeout(r, ms));
};

export const getPagination = (pageNum: number, dataPerPage=10) => {
  const startIndex = (pageNum-1) * dataPerPage
  const endIndex = pageNum * dataPerPage
  return { startIndex, endIndex }
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'instant' })
}

export const useReplaceQueryParams = () => {
  const router = useRouter();

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const queryParams = searchParams as ReadonlyURLSearchParams;
  const urlSearchParams = new URLSearchParams(searchParams + '');

  function setQueryParams(params: any) {
    const search = params + '';
    router.push(`${pathname}?${search}`, {
      scroll: false,
    });
  }

  return { queryParams, urlSearchParams, setQueryParams };
}


export const extractNaverPlaceUrl = (text: string) => {
  const urlRegex = /(https:\/\/\S+)/;
  const matches = text.match(urlRegex);

  if (matches && matches.length > 0) {
    return matches[0]
  }
  return text
}


export function saveBase64Image(base64Data: string, fileName: string) {
  // 변환된 base64 데이터를 Blob으로 만듭니다.
  const byteCharacters = atob(base64Data.split(',')[1]);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: 'image/png' });

  // 브라우저에서 다운로드할 수 있는 링크를 생성합니다.
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;

  // 링크를 클릭하여 다운로드를 시작합니다.
  document.body.appendChild(link);
  link.click();

  // 사용이 끝나면 링크를 제거합니다.
  document.body.removeChild(link);
}