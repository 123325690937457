export const REVIEWS = [
  { name: '김*준', content: '검색 랭킹를 어떻게 올리는지 하나도 몰랐는데 처음부터 끝까지 세세히 알려주셔서 이용하기 편했어요.' },
  { name: '박*준', content: '제가 전에 입력한 정보들이 얼마나 대충 적은건지 반성하게 됐습니다.' },
  { name: '이*민', content: '서비스 이용후에 3번째 페이지에서 2번째 페이지로 랭킹이 올라갔네요 ㅎㅎ 좀 더 노력하면 1번째 페이지에도 들 수 있겠죠...?' },
  { name: '이*윤', content: '랭킹이 왜 낮은지 아무것도 몰랐는데, 덕분에 쉽게 파악 할 수 있었습니다' },
  { name: '정*지', content: '반신반의하면서 이용해봤는데 조금이지만 랭킹이 올라서 신기하네요' },
  { name: '김*호', content: '스토어 관리 전문 업체 1/100 가격으로 동일한 효과를 얻은 것 같아서 좋네요.' },
  { name: '임*현', content: '사용한지 얼마 안돼서 첫번째 페이지로 랭킹이 올라갔네요.. 왜 지금 알았는지 너무 분하네요 ㅋㅋㅋㅋㅋ' },
  { name: '강*호', content: '좋은 서비스 제공해주셔서 감사합니다!!!!' },
  { name: '정*우', content: '지인 추천으로 한번 이용해봤는데 개업 할 때 바로 사용할 껄 그랬네요 너무 좋아요!' },
  { name: '박*훈', content: '사용하자마자 주변 자영업 하시는 지인분들께 공유했네요 ㅎㅎ 잘 사용했습니다!' },
  { name: '이*준', content: '도대체 왜 제 매장이 노출이 안되는지 답답했었는데 이유라도 알 수 있어서 너무 좋습니다' },
  { name: '강*준', content: '사설 업체쓰지말고 그냥 이거 사용할 걸 과거의 나에게 원망스럽네요 ㅠㅠ' },
  { name: '강*수', content: '새로 카페 오픈하면서 이용해봤는데 아직까지는 잘 모르겠지만 효과가 있었으면 좋겠네요~' },
  { name: '최*현', content: '아직 사용한지 얼마 안돼서 효과는 잘 모르겠지만... 효과가 있길 바래봅니다...' },
  { name: '김*연', content: '근처에 미용실이 너무 많아서 플레이스 랭킹 경쟁이 매우 심한편인데, 덕분에 뒤쳐지지 않을 수 있을 것 같네요' },
]