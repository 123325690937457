import Toast from '@/components/Toast';
import { API_ENDPOINT } from '@/config/endpoint/endpoint';
import themeVariables from '@/config/theme/themeVariable';
import { Button, Input, InputBase, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IMaskInput } from 'react-imask';

export default function Contact() {
  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      content: '',
    },
    validate: {
      name: (value) => value ? null : '매장명을 입력해주세요',
      phone: (value) => value ? null : '핸드폰 번호를 입력해주세요',
      content: (value) => value ? null : '문의 내용을 입력해주세요',
    }
  });

  const onSubmit = async () => {
    if (!form.validate().hasErrors) {
      await API_ENDPOINT.inquery.sendServiceRequestInquery({
        name: form.values.name,
        phone: form.values.phone,
        content: form.values.content
      })
      Toast.success('제출 완료! 빠르게 연락드릴게요');
      form.reset()
    }
  }

  return (
    <main className="pt-[80px] pb-[40px] px-4">
      <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
        <div className="max-w-lg mx-auto space-y-3 sm:text-center">
          <div className='text-center uppercase font-bold text-base text-blue-500'>문의</div>
          <div className='text-center text-2xl md:text-3xl font-bold text-gray-700 mb-10 mt-2'>궁금한게 있다면</div>
            <p className='text-center text-sm md:text-base'>
              연락처와 궁금하신 내용을 적어주시면 최대한 빠르게 연락드릴게요
            </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto flex flex-col gap-4">
          <div>
            <div className="font-medium mb-2">
              매장명
            </div>
            <TextInput
              placeholder='데피니션랩 홍대점'
              classNames={themeVariables.input.bordered}
              {...form.getInputProps('name')}
            />
          </div>
          <div>
            <div className="font-medium mb-2">
              핸드폰 번호
            </div>

            <InputBase
              placeholder="010-1234-5678"
              component={IMaskInput}
              mask="01000000000"
              classNames={themeVariables.input.bordered}
              {...form.getInputProps('phone')}
            />

          </div>
          <div>
            <div className="font-medium mb-2">
              문의 내용
            </div>
            <Textarea
              rows={4}
              classNames={themeVariables.input.bordered}
              {...form.getInputProps('content')}
            />
          </div>
          
          <Button
            classNames={{
              label: themeVariables.button.colored.label,
              root: `${themeVariables.button.colored.root} !h-11`
            }}
            onClick={onSubmit}
          >
            문의하기
          </Button>
        </div>
      </div>
    </main>
  )
}