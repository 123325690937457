import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export default function ZoomImage({ imgUrl, className, alt }: { imgUrl: string, className: string, alt?: string }) {
  return (
    <Zoom>
      <img
        src={imgUrl}
        className={`${className} animate__animated animate__fadeIn animate__faster`}
        alt={`${alt}`}
      />
    </Zoom>
  )
};